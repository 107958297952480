html.md {
  .item-password {
    display: grid;
    grid-gap: .5em;

    &.has-mask-toggle {
      grid-template-columns: auto 2.5em;
    }

    &:not(.has-mask-toggle) {
      grid-template-columns: auto;
    }

    ion-button.button-has-icon-only {
      --padding-start: 0;
      --padding-end: 0;
      margin-inline: 0;
    }

    ion-button ion-icon[slot='icon-only'] {
      font-size: 2rem;
    }
  }
}
