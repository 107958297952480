.sv-language-picker {
  &__target {
    display: none;

    &-label {
      // @include typo-body;
      vertical-align: middle;
    }

    &-list {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: none;
        margin: .25em;
        padding: 0;
      }
    }

    a {
      // @include button-base;
      // @include button-size(1, $shape-rect);
      vertical-align: middle;
      padding: (7 / 16 * 1em) (10 / 16 * 1em);
      min-width: 3em;
    }
  }

  &__button {
    // @include button-base;
  }

  &__checkbox {
    // @include visuallyhidden;

    &:checked {
      & ~ .sv-language-picker__button {
        display: none;
      }

      & ~ .sv-language-picker__target {
        display: block;
      }
    }
  }

  // @include language-picker-size;
}
