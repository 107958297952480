// Styles for Material Design mode

html.md {
  $item-padding-horz: 13 / 16 * 1rem;

  ion-action-sheet {
    .action-sheet-button:not(.action-sheet-cancel) {
      contain: unset;
      height: auto;
      padding-top: .5em;
      padding-bottom: .5em;
    }
  }

  ion-button {
    margin: 0;
    margin-inline: 0;
    font-weight: 500;
    letter-spacing: inherit;
    text-transform: inherit;

    &.button-small {
      --padding-start: 1em;
      --padding-end: 1em;
      font-size: .875rem;
      height: 2.25em;
    }

    &:not(.button-small):not(.button-large):not(.button-has-icon-only) {
      --padding-start: 1em;
      --padding-end: 1em;
      font-size: 1rem;
      height: 2.5em;
    }

    &.button-large {
      --padding-start: 1.75em;
      --padding-end: 1.75em;
      font-size: 1.25rem;
      height: 2.75em;
    }

    // &.button-has-icon-only {
    //   --padding-start: .5em;
    //   --padding-end: .5em;
    //   margin-inline: 0;
    // }
  }

  ion-toast.push-notification {
    &.has-header.has-message {
      &::part(header) {
        font-weight: bold;
      }
    }

    &::part(icon) {
      color: theme('colors.yellow.500');
    }

    &::part(button) {
      color: theme('colors.yellow.500');
      letter-spacing: inherit;
      text-transform: inherit;
    }
  }

  ion-note[slot='error'],
  ion-note[slot='helper'] {
    padding-top: .375rem;
    font-size: .8125em;
    line-height: 1.375;
  }
}
