html.md {
  $item-padding-horz: 13 / 16 * 1rem;

  ion-item.item-datetime {
    $margin-vert: 3 / 16 * 1rem;

    ion-label {
      margin-top: $margin-vert;
      margin-bottom: $margin-vert;
    }

    ion-note {
      margin-left: -$item-padding-horz + (3 / 16 * 1rem);
    }
  }
}
