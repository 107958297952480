.sv-table {
  display: table;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 1.5em;
  border-collapse: collapse;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        box-sizing: border-box;
        padding: .6em 1em;
        line-height: 1.5;
        text-align: left;
        vertical-align: top;
        border-top: 1px solid theme('colors.grey.300');

        &.sv-table__cell {
          text-align: left;

          &--numeric {
            text-align: right;
          }

          &--full-width {
            width: 100%;
          }

          &--nowrap {
            white-space: nowrap;
          }
        }
      }
    }
  }

  > thead > tr {
    > th,
    > td {
      vertical-align: middle;
      border-bottom: 2px solid theme('colors.grey.300');
    }
  }

  // Add extra vert padding to tbody
  &:not(.sv-table--bordered) {
    $padding: max(.8em, 0 * .5, 1.5em * .5);

    > thead > tr {
      > th,
      > td {
        padding: .8em 1em $padding;
      }
    }

    > tfoot > tr {
      > th,
      > td {
        padding: $padding 1em .8em;
      }
    }
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid theme('colors.grey.300');
  }

  &--bordered {
    border: 1px solid theme('colors.grey.300');

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border: 1px solid theme('colors.grey.300');
        }
      }
    }

    > thead > tr {
      > th,
      > td {
        border-bottom-width: 2px;
      }
    }
  }

  &--striped {
    > tbody > tr:nth-of-type(odd) {
      background-color: theme('colors.grey.50');
    }
  }

  &--hover {
    > tbody > tr:hover {
      background-color: theme('colors.grey.100');
    }
  }
}
