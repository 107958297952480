// TODO Move to Tailwind config for base/preflight styles
@import './base';

html {
  font-family: theme('fontFamily.sans');
}

a {
  color: theme('colors.blue.500');
  text-decoration: none;
}

a:focus,
a:hover {
  color: theme('colors.blue.500');
  text-decoration: underline;
}

hr {
  clear: both;
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: 0;
  background: theme('colors.grey.300');
  color: theme('colors.grey.300');
}

p {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

code,
pre {
  font-family: theme('fontFamily.mono');
  font-size: .75em;
}

code {
  line-height: inherit;
}

pre {
  line-height: 1.5em;

  code {
    font-size: 1em;
  }
}

blockquote {
  @include typo-quote;
}
