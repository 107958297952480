@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tw-max-w-container {
    @apply tw-max-w-6xl;
  }

  .tw-page {
    @apply tw-mx-auto tw-w-11/12 tw-max-w-6xl tw-py-4 md:tw-py-6 lg:tw-py-8 xl:tw-py-10;
  }
}
