// COMMON

// $root-font-size:                        100%;

$font-size-base:                        1em;
$line-height-base:                      24 / 16;
$line-height-computed:                  $line-height-base * 1em;

// $modular-scale-ratio:                   $major-second;
// $modular-scale-base:                    $font-size-base;

$border-radius-base:                    .25em;

$padding-horz-base:                     1em;
$padding-vert-base:                     ($padding-horz-base * .8);



// SIZES
//
// These variables are used for sizing of components, usually with font-size and padding.
// Their naming is not related to breakpoints. For modular scale variables, see
// https://github.com/thoughtbot/bourbon/blob/master/core/bourbon/helpers/_scales.scss

$size-xsmall:                           13 / 16; // Similar size to <small>. Used for captions and small print.
$size-small:                            14 / 16;
// $size-medium:                           1;
// $size-large:                            $minor-third;
// $size-xlarge:                           $perfect-fourth;



// TYPOGRAPHY

$typography-map: (
  base: (
    display-4-ratio:                       28 / 16,
    display-3-ratio:                       24 / 16,
    display-2-ratio:                       20 / 16,
    display-1-ratio:                       18 / 16,
    // headline-ratio:                        18 / 16,
    // title-ratio:                           17.5 / 16,

    display-4-line-height:                 20 / 16,
    display-3-line-height:                 20 / 16,
    display-2-line-height:                 22 / 16,
    display-1-line-height:                 22 / 16,
    // headline-line-height:                  22 / 16,
    // title-line-height:                     22 / 16,

    display-4-margin-top:                  20 / 16 * 0,
    display-3-margin-top:                  20 / 16 * .8em * 2,
    display-2-margin-top:                  22 / 16 * .8em * 2,
    display-1-margin-top:                  22 / 16 * .8em * 2,
    // headline-margin-top:                   22 / 16 * .8em * 2,
    // title-margin-top:                      22 / 16 * .8em * 2,

    display-4-margin-bottom:               20 / 16 * .8em,
    display-3-margin-bottom:               20 / 16 * .8em,
    display-2-margin-bottom:               22 / 16 * .8em,
    display-1-margin-bottom:               22 / 16 * .8em,
    // headline-margin-bottom:                22 / 16 * .8em,
    // title-margin-bottom:                   22 / 16 * .8em,
  ),
  lg: (
    display-4-ratio:                       38 / 16,
    display-3-ratio:                       28 / 16,
    display-2-ratio:                       24 / 16,
    display-1-ratio:                       20 / 16,
    // headline-ratio:                        18 / 16,
    // title-ratio:                           17.5 / 16,

    display-4-line-height:                 18 / 16,
    display-3-line-height:                 19 / 16,
    display-2-line-height:                 20 / 16,
    display-1-line-height:                 20 / 16,
    // headline-line-height:                  22 / 16,
    // title-line-height:                     22 / 16,

    display-4-margin-top:                  18 / 16 * 0,
    display-3-margin-top:                  19 / 16 * .8em * 2,
    display-2-margin-top:                  20 / 16 * .8em * 2,
    display-1-margin-top:                  20 / 16 * .8em * 2,
    // headline-margin-top:                   22 / 16 * .8em * 2,
    // title-margin-top:                      22 / 16 * .8em * 2,

    display-4-margin-bottom:               18 / 16 * .8em,
    display-3-margin-bottom:               19 / 16 * .8em,
    display-2-margin-bottom:               20 / 16 * .8em,
    display-1-margin-bottom:               20 / 16 * .8em,
    // headline-margin-bottom:                22 / 16 * .8em,
    // title-margin-bottom:                   22 / 16 * .8em,
  )
);

// Block-level elements
$block-margin-top:                      $line-height-computed;
$block-margin-bottom:                   $line-height-computed;

// Lists
$ordered-list-padding-horz:             1.75em;
$unordered-list-padding-horz:           1.75em;

// Base note
$note-padding-vert--inline:             .25em;
$note-padding-horz--inline:             (.25em * 1.25);

$note-padding-vert--block:              $padding-vert-base;
$note-padding-horz--block:              $padding-horz-base;

$note-border-radius:                    $border-radius-base;
$note-border-width:                     .5em;

$note-bg:                               theme('colors.blue.50');
$note-heading:                          theme('colors.blue.700');
$note-border:                           theme('colors.blue.500');

// Tip note
$tip-bg:                                theme('colors.green.50');
$tip-heading:                           theme('colors.green.700');
$tip-border:                            theme('colors.green.500');

// Instruction instruction
$instruction-bg:                        theme('colors.orange.50');
$instruction-heading:                   theme('colors.orange.700');
$instruction-border:                    theme('colors.orange.500');

// Quote
$quote-bg:                              theme('colors.grey.50');
$quote-heading:                         theme('colors.grey.700');
$quote-border:                          theme('colors.grey.500');
