:root {
  --ion-color-primary: #008bb2;
  --ion-color-primary-rgb: 0,139,178;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #007a9d;
  --ion-color-primary-tint: #1a97ba;

	--ion-color-secondary: #0daa88;
	--ion-color-secondary-rgb: 13,170,136;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0b9678;
	--ion-color-secondary-tint: #25b394;

  --ion-color-tertiary: #2f519e;
  --ion-color-tertiary-rgb: 47,81,158;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #29478b;
  --ion-color-tertiary-tint: #4462a8;

  --ion-color-success: #45b449;
  --ion-color-success-rgb: 69,180,73;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #3d9e40;
  --ion-color-success-tint: #58bc5b;

  --ion-color-warning: #ffa600;
  --ion-color-warning-rgb: 255,166,0;
  --ion-color-warning-contrast: #5d180e;
  --ion-color-warning-contrast-rgb: 93,24,14;
  --ion-color-warning-shade: #e09200;
  --ion-color-warning-tint: #ffaf1a;

  --ion-color-danger: #e02e2e;
  --ion-color-danger-rgb: 224,46,46;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #c52828;
  --ion-color-danger-tint: #e34343;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
