html.md {
  $item-padding-horz: 13 / 16 * 1rem;

  .item-select {
    display: grid;
    grid-gap: .5em;

    &.has-clear-input {
      grid-template-columns: auto 2em;
    }

    &:not(.has-clear-input) {
      grid-template-columns: auto;
    }

    ion-button.button-has-icon-only {
      --padding-start: .5em;
      --padding-end: .5em;
      margin-inline: 0;
    }
  }

  ion-modal {
    ion-content.item-select-modal-content {
      ion-list {
        ion-item {
          --min-height: #{44 / 16 * 1rem};
          --padding-start: #{$item-padding-horz};
          --inner-padding-end: #{$item-padding-horz};
        }

        ion-item.item-checkbox,
        ion-item.item-radio {
          &.item-lines-full {
            ion-checkbox.in-item,
            ion-radio.in-item {
              margin-top: 1rem;
              margin-bottom: 1rem;

              &[slot='start'] {
                margin-inline: 0 1rem;
              }
            }
          }
        }
      }
    }

    ion-footer.item-select-modal-footer {
      ion-button {
        text-transform: uppercase;
      }
    }
  }
}
