html.md {
  $item-padding-horz: 13 / 16 * 1rem;

  ion-item.item-input {
    --min-height: #{44 / 16 * 1rem};
    --padding-start: #{$item-padding-horz};
    --inner-padding-end: #{$item-padding-horz};
    --border-color: theme('colors.grey.600');

    &.has-error {
      --border-color: theme('colors.red.400');
    }

    ion-note {
      margin-left: -$item-padding-horz + (3 / 16 * 1rem);
    }

    ion-icon[slot] {
      margin-top: .25em;
      margin-bottom: .25em;
    }

    &.item-label-floating {
      ion-label {
        transform: translateY(10 / 16 * 1rem);
      }

      ion-input {
        --padding-top: 0;
        --padding-bottom: 0;
        margin-top: -.625rem;
        margin-bottom: 0;
      }

      &.item-has-focus,
      &.item-has-value {
        ion-label {
          transform: translateY(-9 / 16 * 1rem) scale(12 / 16 * 1) !important;
        }

        ion-input {
          margin-top: 0;
          margin-bottom: -.5rem;
        }
      }
    }
  }
}
