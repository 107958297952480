@import '../../core/theme/base';

.sv-practice-toc {
  &__header {
    background: theme('colors.grey.100');
    border-top-left-radius: var(--ion-border-radius);
    border-top-right-radius: var(--ion-border-radius);
    padding: 1em;

    strong {
      color: theme('colors.indigo.500');
    }

    ion-grid {
      --ion-grid-padding: 0;
    }

    @include media-breakpoint-down(md) {
      ion-col {
        padding: 0;
      }
    }

    @include media-breakpoint-up(md) {
      ion-grid {
        margin-right: 36px + 2px + 2px;
      }
    }
  }

  &__footer {
    background: theme('colors.grey.100');
    border-bottom-left-radius: var(--ion-border-radius);
    border-bottom-right-radius: var(--ion-border-radius);
    padding: 1.25em 1em 1em;
    text-align: center;
  }

  ion-progress-bar {
    --background: theme('colors.grey.300');
    --progress-background: theme('colors.green.500');
    $height: 8px;
    height: $height;
    border-radius: $height * .5;
    margin: 8px 0;
  }

  ion-accordion {
    // Chapter
    ion-item[slot='header'] {
      --background: theme('colors.grey.100');
      --background-focused: theme('colors.cyan.100');
      --background-focused-opacity: 1;
      --background-hover: theme('colors.cyan.100');
      --background-hover-opacity: 1;

      ion-grid {
        --ion-grid-padding: 0;
      }

      @include media-breakpoint-down(md) {
        ion-col {
          &:nth-child(1) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
          }

          &:nth-child(2) {
            padding-top: .25em;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      @include media-breakpoint-up(md) {
        ion-progress-bar {
          max-width: 224px;
        }
      }
    }

    // Sections
    [slot='content'] {
      ion-list {
        border-left: 1px solid theme('colors.grey.200');
        border-right: 1px solid theme('colors.grey.200');
      }

      ion-grid {
        --ion-grid-padding: 0;
      }

      @include media-breakpoint-down(md) {
        ion-label {
          margin: .5em 0;
        }

        ion-col {
          &:nth-child(1) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
          }

          &:nth-child(2) {
            padding-top: .25em;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      @include media-breakpoint-up(md) {
        ion-list {
          padding: 1em 0;
        }

        ion-item {
          --min-height: #{28px + 20px};
        }

        ion-label {
          margin: 0;
        }

        ion-grid {
          margin-right: 36px + 2px;
        }

        ion-col {
          &:nth-child(1) {
            padding-top: (28px - 16px) / 2;
          }
        }
      }
    }
  }
}
