html.md {
  ion-item.item-radio {
    &.item-lines-none {
      $margin-vert: 3 / 16 * 1rem;
      $offset-vert: 3 / 16 * 1rem;

      --background: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background-hover: transparent;
      --padding-start: #{$margin-vert + $offset-vert};
      --inner-padding-end: #{$margin-vert + $offset-vert};
      --min-height: 0;
      align-items: flex-start;

      ion-label {
        margin-top: $margin-vert;
        margin-bottom: $margin-vert;
        white-space: normal !important;
      }

      ion-checkbox.in-item,
      ion-radio.in-item {
        margin-top: $margin-vert + $offset-vert;
        margin-bottom: $margin-vert;

        &[slot='start'] {
          margin-inline: 0 .875rem;
        }
      }
    }
  }
}
