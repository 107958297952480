// Styles for Material Design and iOS modes

// roboto-regular - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v30-latin/roboto-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v30-latin/roboto-v30-latin-regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('../fonts/roboto-v30-latin/roboto-v30-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  // font-display: swap;
}

// roboto-700 - latin
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v30-latin/roboto-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-v30-latin/roboto-v30-latin-700.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('../fonts/roboto-v30-latin/roboto-v30-latin-700.ttf') format('truetype'); /* Safari, Android, iOS */
  // font-display: swap;
}

// roboto-slab-regular - latin
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
       url('../fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('../fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  // font-display: swap;
}

ion-datetime {
  --ion-color-base: theme('colors.cyan.500');
}

ion-item.item-datetime {
  --background: transparent;
  --background-activited: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
}

ion-icon {
  vertical-align: middle;
}

ion-split-pane[content-id='main'] {
  --side-min-width: 16em;
  --side-max-width: 16em;
}

@media (min-width: 1600px) {
  ion-split-pane[content-id='main'] {
    --side-min-width: 16em;
    --side-max-width: 20em;
  }
}
