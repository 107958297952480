html.md {
  ion-list.list-radio-group {
    $margin-vert: 3 / 16 * 1rem;
    $offset-vert: 3 / 16 * 1rem;

    padding-top: 0;
    padding-bottom: 0;

    // NOTE IonListHeader is not used because text inside .list-header-inner does not wrap. The element cannot be styled because it has no part attribute.
    // ion-list-header {
    //   padding-inline: 0 0;
    //   font-size: inherit;
    //   margin-bottom: .25rem;
    //   min-height: unset;

    //   ion-label {
    //     flex: 0 0 auto !important;
    //     width: auto !important;
    //     min-width: unset !important;
    //     max-width: unset !important;
    //   }
    // }

    .list-header {
      margin-bottom: .25rem;
    }

    ion-note {
      padding-left: $margin-vert + $offset-vert;
    }
  }
}
