/* slate from https://tailwindcss.com/docs/customizing-colors */
ion-menu ion-content {
  --background: #334155;
}

ion-menu ion-list ion-list-header {
  color: #e2e8f0;
  text-transform: uppercase;
  min-height: 56px; /* Same as ion-toolbar */
}

ion-menu .list-md {
  background: transparent;
}

ion-menu ion-item {
  --background: transparent;
  --color: #e2e8f0;
}

ion-menu ion-icon {
  color: #e2e8f0;
}
