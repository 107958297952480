@mixin link-variant ($color, $decoration, $hover-color, $hover-decoration) {
  color: $color;
  text-decoration: $decoration;

  &:focus,
  &:hover {
    color: $hover-color;
    text-decoration: $hover-decoration;
  }
}

@mixin typo-children {
  > p,
  > ol,
  > ul {
    @content;
  }
}

@mixin typo-descendants {
  p,
  ol,
  ul {
    @content;
  }
}

@mixin typo-display-4 ($block: false, $breakpoint: base) {
  font-size: map-deep-get($typography-map, $breakpoint, display-4-ratio) * 1em;
  line-height: map-deep-get($typography-map, $breakpoint, display-4-line-height);

  @if $block {
    margin-top: map-deep-get($typography-map, $breakpoint, display-4-margin-top);
    margin-bottom: map-deep-get($typography-map, $breakpoint, display-4-margin-bottom);
  }
}

@mixin typo-display-3 ($block: false, $breakpoint: base) {
  font-size: map-deep-get($typography-map, $breakpoint, display-3-ratio) * 1em;
  line-height: map-deep-get($typography-map, $breakpoint, display-3-line-height);

  @if $block {
    margin-top: map-deep-get($typography-map, $breakpoint, display-3-margin-top);
    margin-bottom: map-deep-get($typography-map, $breakpoint, display-3-margin-bottom);
  }
}

@mixin typo-display-2 ($block: false, $breakpoint: base) {
  font-size: map-deep-get($typography-map, $breakpoint, display-2-ratio) * 1em;
  line-height: map-deep-get($typography-map, $breakpoint, display-2-line-height);

  @if $block {
    margin-top: map-deep-get($typography-map, $breakpoint, display-2-margin-top);
    margin-bottom: map-deep-get($typography-map, $breakpoint, display-2-margin-bottom);
  }
}

@mixin typo-display-1 ($block: false, $breakpoint: base) {
  font-size: map-deep-get($typography-map, $breakpoint, display-1-ratio) * 1em;
  line-height: map-deep-get($typography-map, $breakpoint, display-1-line-height);

  @if $block {
    margin-top: map-deep-get($typography-map, $breakpoint, display-1-margin-top);
    margin-bottom: map-deep-get($typography-map, $breakpoint, display-1-margin-bottom);
  }
}

@mixin typo-body ($block: false) {
  font-size: $font-size-base;
  line-height: $line-height-computed;

  @if $block {
    margin-top: $block-margin-top;
    margin-bottom: $block-margin-bottom;
  }
}

@mixin typo-small ($base: $font-size-base) {
  font-size: $base * $size-small;
  line-height: $line-height-computed;
}

@mixin typo-xsmall ($base: $font-size-base) {
  font-size: $base * $size-xsmall;
  line-height: $line-height-computed;
}

// Styling shared between ordered and unordered lists. Do not use elsewhere.
@mixin typo-list {
  font-size: $font-size-base;
  line-height: $line-height-computed;
  margin-top: $block-margin-top;
  margin-bottom: $block-margin-bottom;

  li {
    margin-top: $block-margin-top / 3;
    margin-bottom: $block-margin-bottom / 3;
  }

  > li {
    padding-left: .375em;
  }

  ol,
  ul {
    margin-top: $block-margin-top / 2;
    margin-bottom: $block-margin-bottom / 2;
  }
}

@mixin typo-ordered-list {
  @include typo-list;
  list-style-type: decimal;
  padding-left: $ordered-list-padding-horz;
}

@mixin typo-unordered-list {
  @include typo-list;
  list-style-type: disc;
  padding-left: $unordered-list-padding-horz;
}

@mixin typo-note-base (
  $background: $note-bg,
  $border: $note-border,
  $border-width: $note-border-width,
  $border-radius: $note-border-radius,
  $padding: $note-padding-vert--block $note-padding-horz--block
) {
  display: block;
  background: $background;
  border-left: $border-width solid $border;
  border-radius: $border-radius;
  margin: $block-margin-top 0 $block-margin-bottom;
  padding: $padding;
}

@mixin typo-note-children {
  @include typo-children {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  > ol,
  > ul {
    &:first-child {
      li:first-child {
        margin-top: 0;
      }
    }

    &:last-child {
      li:last-child {
        margin-bottom: 0;
      }
    }
  }

  > ol {
    padding-left: $ordered-list-padding-horz * .8;
  }

  > ul {
    padding-left: $unordered-list-padding-horz * .8;
  }
}

@mixin typo-quote {
  @include typo-note-base (
    $background: $quote-bg,
    $border: $quote-border,
    $border-width: $note-border-width,
    $border-radius: $note-border-radius,
    $padding: $note-padding-vert--block $note-padding-horz--block
  );
  @include typo-note-children;

  footer {
    @include typo-small;
    display: block;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

@mixin typo-note {
  @include typo-body;
  @include typo-note-base (
    $background: $note-bg,
    $border: $note-border,
    $border-width: $note-border-width,
    $border-radius: $note-border-radius,
    $padding: $note-padding-vert--block $note-padding-horz--block
  );
  @include typo-note-children;

  .note-header {
    color: $note-heading;
    display: inline;
    font-weight: bold;

    + ol,
    + ul {
      margin-top: max($block-margin-top, $block-margin-bottom) * .25;
    }
  }

  &.tip {
    background: $tip-bg;
    border-left-color: $tip-border;

    .note-header {
      color: $tip-heading;
    }
  }

  &.instruction,
  &.instructions {
    background: $instruction-bg;
    border-left-color: $instruction-border;

    .note-header {
      color: $instruction-heading;
    }
  }

  // TODO Deprecate .inlinetip in favour of .inline
  &.inlinetip {
    display: inline;
    border-left: 0;
    border-radius: 0;
    margin: 0;
    padding: $note-padding-vert--inline $note-padding-horz--inline;
  }

  &.inlinetip {
    background: $tip-bg;
  }
}

@mixin typo-code {
  font-family: $code-font-family;
  font-size: $code-font-size;
  font-weight: $code-font-weight;
  line-height: $code-line-height;
  color: $code-color;
}

@mixin typo-notation {
  font-family: $notation-font-family;
  font-size: $notation-font-size;
  font-weight: $notation-font-weight;
  line-height: $notation-line-height;
  color: $notation-color;
  white-space: nowrap;
  display: inline-block;
  text-indent: 0;
}
